<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="onSubmit"
    >
      <dialog-base
        v-model="pageData.uploadFilesActive"
        width="1000px"
        height="auto"
        persistent
      >
        <template #title>
          字幕文件上传
        </template>

        <template #body>
          <file-input-plus
            v-model="pageData.uploadFiles"
            accept-type=".srt"
            is-required
          ></file-input-plus>
        </template>

        <template #actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              class="mx-2"
              color="success"
              type="submit"
              @click="onSubmit"
            >
              保存
            </v-btn>
            <v-btn
              color="primary"
              @click="pageData.uploadFilesActive = false"
            >
              关闭
            </v-btn>
          </div>
        </template>
      </dialog-base>
    </v-form>

    <v-card>
      <v-row class="px-2 ma-0">
        <v-col
          v-for="(queryComponent, index) in queryComponents"
          :key="`${queryComponent.componentName}-${index}`"
          cols="12"
          :md="queryComponent.mdCols"
        >
          <component
            :is="queryComponent.componentName"
            v-model="proxyQueryData[queryComponent.VModelKey]"
            :class="queryComponent.class"
            :placeholder="queryComponent.label"
            :label="queryComponent.label"
            :items="getDataWithKey(queryComponent.items, queryComponent.itemsKey)"
            :clearable="queryComponent.clearable || true"
            no-data-text="暂无数据"
            hide-details
            outlined
            dense
            v-bind="queryComponent.componentProps"
            v-on="queryComponent.eventHandlers"
            @keydown.enter="searchEvent"
          ></component>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <div
        class="d-flex justify-space-between ma-4"
      >
        <div class="text-h5 font-weight-black ml-3">
          {{ pageData.title }}
        </div>
        <div>
          <btn-group
            :btn-group-list="btnGroupList"
          ></btn-group>
        </div>
      </div>
      <!-- table -->
      <v-data-table
        v-model="pageData.selectedListItem"
        :headers="tableColumns"
        :items="listTable"
        :sort-by="proxyQueryData.sortBy"
        :sort-desc="proxyQueryData.sortDesc"
        :page="page"
        :items-per-page="pageSize"
        hide-default-header
        hide-default-footer
        :loading="loading"
        item-key="index"
        no-data-text="暂无数据"
        no-results-text="未找到相关数据"
        loading-text="加载中，请稍等..."
      >
        <template
          #header="{props}"
        >
          <thead>
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="{ tableHeader: header.sortable !== false }"
                :style="{ textAlign: header.align }"
                @click="clickSort(header)"
              >
                <!-- 全选框 -->
                <div
                  v-if="header.value === 'data-table-select'"
                >
                  <v-simple-checkbox
                    v-ripple
                    :value="props.everyItem"
                    @click="clickCheckAll(props)"
                  ></v-simple-checkbox>
                </div>
                <!-- 自定义表头内容 -->
                <div
                  class="tableHeaderCol"
                >
                  {{ header.text.toUpperCase() }}
                  <v-icon
                    v-if="header.sortable !== false && header.value === proxyQueryData.sortBy"
                    class="tableHeaderSortArrow"
                    size="18"
                  >
                    {{ proxyQueryData.sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                  </v-icon>
                  <v-icon
                    v-if="header.sortable !== false && header.value !== proxyQueryData.sortBy"
                    class="tableHeaderSortArrowHide"
                    size="18"
                  >
                    mdi-arrow-down
                  </v-icon>
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template
          v-for="col in Object.keys(tableResolver)"
          #[`item.${col}`]="{item}"
        >
          {{ typeof tableResolver[col] === 'function' ? tableResolver[col](item[col]) : item[col] }}
        </template>

        <template #[`item.actions`]="{item}">
          <div
            @click.capture="bindActionsItem(item)"
          >
            <icon-btn-group
              :item="item"
              :btn-list="tableBtnList"
            ></icon-btn-group>
          </div>
        </template>
      </v-data-table>
      <v-divider class="mb-4"></v-divider>

      <v-card-text v-if="!loading">
        <v-row
          class="mx-2"
        >
          <v-col
            sm="2"
            cols="10"
          >
            <v-select
              v-model="pageSize"
              dense
              label="每页行数"
              item-text="title"
              item-value="value"
              hide-details="auto"
              :items="pageOptions"
            ></v-select>
          </v-col>

          <v-col
            sm="8"
            cols="10"
          >
            <v-pagination
              v-model="page"
              :length="pageLength"
              :total-visible="pageVisible"
            ></v-pagination>
          </v-col>

          <v-col
            sm="2"
            cols="10"
          >
            <v-text-field
              v-model="pageInput"
              outlined
              dense
              hide-details
              type="number"
              label="跳转页数"
              placeholder="跳转页数"
              @keydown.enter="pageJump"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <empower-auth v-if='showIframe' :iframeUrl='iframeUrl' @onSuccess='success' @onError='lose' />
    </v-card>
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import { VTextField, VSelect, VAutocomplete } from 'vuetify/lib/components'
import {
  modalEnquireTitle,
  modalFail,
  toastFail,
  toastSuccess,
} from '@core/utils/prompt'
import { getDataWithKey } from '@core/utils/dataProcess'
import { getTime } from '@core/utils/formatTime'
import store from '@/store'
import ImgWithTag from '@/views/components/common/img-with-tag/ImgWithTag.vue'
import TextFieldDataPicker from '@/views/components/form-components/date-picker/TextFieldDataPicker.vue'
import BtnGroup from '@/views/components/common/btn-group/BtnGroup.vue'
import HeaderTitle from '@/views/components/common/headerTitle/HeaderTitle.vue'
import IconBtnGroup from '@/views/components/common/icon-btn-group/IconBtnGroup.vue'
import FileInputPlus from '@/views/components/file-input-plus/FileInputPlus.vue'
import DialogBase from '@/views/components/dialog-base/DialogBase.vue'
import TooltipBase from '@/views/components/common/tooltip-base/TooltipBase.vue'
import useList from './useList'
import EmpowerAuth from './EmpowerAuth.vue'

export default {
  name: 'MyChannelAuth',
  components: {
    TooltipBase,
    IconBtnGroup,
    FileInputPlus,
    HeaderTitle,
    DialogBase,
    ImgWithTag,
    BtnGroup,
    VSelect,
    VTextField,
    VAutocomplete,
    TextFieldDataPicker,
    EmpowerAuth,
  },
  inheritAttrs: false,
  props: {},
  setup() {
    const {
      pageData,
      tableColumns,
      tableResolver,
      proxyQueryData,
      queryComponents,
      listTableCount,
      listTable,
      loading,
      page,
      pageSize,
      pageInput,
      pageLength,
      pageVisible,
      pageOptions,
      tableBtnList,
      btnGroupList,
      fetchFilterData,
      bindActionsItem,
      clickCheckAll,
      searchEvent,
      routeChange,
      clickSort,
      fetchList,
      pageJump,
      iframeUrl,
      showIframe
    } = useList()

    const form = ref(null)
    const valid = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const onSubmit = () => {
      const submitData = pageData.uploadFiles.map(item => {
        // eslint-disable-next-line no-param-reassign
        item.subtitleKey = item.key
        // eslint-disable-next-line no-param-reassign
        item.subtitleName = item.name

        return item
      })
      console.log(submitData)
      if (valid.value) {
        if (!pageData.uploadFiles.every(item => item.name.endsWith('.srt'))) {
          toastFail('仅能上传.srt结尾的字幕文件！')

          return
        }
        JSON.parse(JSON.stringify(pageData.uploadFiles))
        modalEnquireTitle('确认要上传字幕文件？', '点击确认后会上传相关字幕文件').then(answer => {
          if (answer.isConfirmed === true) {
            store.dispatch('subtitleUploadList/saveSubtitle', submitData).then(res => {
              if (res.data.status === 200) {
                fetchList()
                pageData.uploadFilesActive = false
                pageData.uploadFiles = []
                form.value.reset()
                toastSuccess(res.data.message || '成功上传字幕文件')
              } else {
                modalFail(res.data.message)
              }
            }).catch(error => {
              modalFail(error.response.data.message)
              console.log(error)
            })
          }
        })
      } else {
        validate()
      }
    }

    // 成功回调后刷新页面
    function success() {
      window.location.reload();
    }
    function lose() {
      fetchList()
      showIframe.value = false
      iframeUrl.value = ''
    }
    return {
      form,
      valid,
      validate,
      onSubmit,
      success,
      lose,
      pageData,
      tableColumns,
      tableResolver,
      proxyQueryData,
      queryComponents,
      listTableCount,
      listTable,
      loading,
      page,
      pageSize,
      pageInput,
      pageLength,
      pageVisible,
      pageOptions,
      tableBtnList,
      btnGroupList,
      fetchFilterData,
      bindActionsItem,
      clickCheckAll,
      searchEvent,
      routeChange,
      clickSort,
      fetchList,
      pageJump,
      iframeUrl,
      getTime,
      getDataWithKey,
      showIframe
    }
  },
}
</script>

<style scoped lang="scss">
.v-card__text {
  padding: 0;
}

.invoice-list-search {
  margin-bottom: -26px;
}
.ellipsisText {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableHeader:hover {
  cursor: pointer;
  .tableHeaderSortArrowHide {
    display: inline-flex;
    opacity: 50%;
  }
}

.tableHeaderCol {
  position: relative;
}

.tableHeaderSortArrowHide {
  display: none;
  position: absolute;
}
.tableHeaderSortArrow {
  position: absolute;
}

.ellipsisText {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
