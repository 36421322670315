<script>
import { ref, onMounted } from '@vue/composition-api'
import { requestGet } from '@core/utils/form'

export default {
  name: 'EmpowerAuth',
  props: {
    iframeUrl: {
      type: String,
      default: ''
    }
  },
  setup(props,{ emit }) {
    const iform = ref()
    const url = ref(props.iframeUrl)

    function handleIframeLoad(event) {
      if (event.data && event.data.authStatus) {
        requestGet('/client/userInfo').then(res => {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          emit('onSuccess')
        })
        return
      }

      if (event.data && event.data.authStatus === false) {
        emit('onError')
      }

    }
    function handleLoad() {
      window.addEventListener('message', handleIframeLoad)
    }

    // onMounted(() => setTimeout(() => {
    //   url.value = 'http://localhost:8088/callback/Google'
    // }, 5000))
    return {
      iform,
      url,
      handleLoad,
    }
  }
}
</script>

<template>
  <v-dialog
    :value='true'
    :persistent='true'
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card tile>
      <v-card-title>
        <div class='back' @click="$emit('onError')">
            <div style='transform: rotate(180deg); display: inline-block'>
              <v-icon size="26">
              mdi-export
            </v-icon>
            </div>
            返回
          </div>
      </v-card-title>
      <iframe ref='iform' :src='url' @load='handleLoad' height='100%'/>
    </v-card>
  </v-dialog>
</template>

<style scoped lang='scss'>
.back {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
</style>
