import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import {
  modalEnquireTitle,
  toastInfo,
} from '@core/utils/prompt'
import {
  filterDataProcess, getDataWithKey,
} from '@core/utils/dataProcess'
import {
  authResultOptions,
  pageOptions,
} from '@core/utils/options'
import { genResolverFromOptions } from '@core/utils/resolve'
import { requestGet } from '@core/utils/form'
import { isObject } from '@core/utils'
import router from '@/router'
import { getParsedUserData, userInfo } from '@core/utils/userData'

export default function useBatchList() {
  // 列表column
  const tableColumns = [
    // {
    //   text: '合同编号', value: 'contractNum', sortable: false, align: 'center',
    // },
    {
      text: '平台', value: 'platform', sortable: false, align: 'center',
    },
    {
      text: '频道名', value: 'channelName', sortable: false, align: 'center',
    },
    // {
    //   text: '频道地址', value: 'channelLink', sortable: false, align: 'center', width: 200,
    // },
    {
      text: '授权结果', value: 'result', sortable: false, align: 'center',
    },
    {
      text: '授权详情', value: 'remark', sortable: false, align: 'center',
    },
    {
      text: '授权时间', value: 'confirmedAt', sortable: false, align: 'center',
    },
    {
      text: '操作', value: 'actions', sortable: false, align: 'center',
    },
  ]

  // 列表数据需解析时，在下面添加key对应tableColumns列value的解析函数
  const tableResolver = {
    result: genResolverFromOptions(authResultOptions),
  }

  // 查询参数
  const queryDataOri = {
    sortBy: null,
    sortDesc: null,
    channelName: null,
  }
  const queryData = reactive({ ...queryDataOri })

  // 创建一个拦截器对象，对数据进行预处理（因路由来的数据都是String类型，故需要处理）
  const handler = {
    get(target, key) {
      // 在访问属性时收集依赖
      // 这个过程由Vue自动处理，无需手动编写依赖收集逻辑
      // 返回处理后相应的值
      // if (key === 'sortDesc') return booleanParser(Reflect.get(target, key))
      // if (['status'].includes(key)) return intParser(Reflect.get(target, key))

      return Reflect.get(target, key)
    },
    set(target, key, value) {
      // 更新属性值
      // 这个过程由Vue自动触发视图更新
      return Reflect.set(target, key, value)
    },
    deleteProperty(target, key) {
      // 删除属性
      // 这个过程由Vue自动触发视图更新
      return Reflect.deleteProperty(target, key)
    },
  }
  const proxyQueryData = new Proxy(queryData, handler)

  const pageDataOri = {
    title: router.currentRoute.meta.pageTitle,
    routerPushName: 'subtitleUploadList',
    selectedListItem: [],
    actionsItem: {},
  }
  const pageData = reactive(JSON.parse(JSON.stringify(pageDataOri)))

  const queryComponents = reactive([
    {
      mdCols: 3,
      componentName: 'HeaderTitle',
      label: '我的频道确权',
    },
    {
      VModelKey: 'channelName',
      mdCols: 3,
      componentName: 'VTextField',
      search: true,
      label: '频道名',
    },
  ])

  // 列表数据
  const listTable = ref([])
  const listTableCount = ref(0)
  const loading = ref(true)

  // 分页
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(1)
  const pageVisible = ref(9)
  const pageInput = ref(null)

  // 获取列表查询参数
  function fetchFilterData() {
    const filterData = {
      ...proxyQueryData,
      page: page.value,
      pageSize: pageSize.value,
    }

    return filterDataProcess(filterData)
  }

  // 路由变化时赋值查询参数
  const routeChange = routeQuery => {
    page.value = parseInt(routeQuery.page, 10) || 1
    pageSize.value = parseInt(routeQuery.pageSize, 10) > pageOptions[pageOptions.length - 1].value ? 10 : parseInt(routeQuery.pageSize, 10) || 10
    Object.assign(proxyQueryData, queryDataOri, routeQuery)
  }

  // 获取路由的query参数并由routeChange函数赋值查询参数
  routeChange(router.currentRoute.query)

  // 页面跳转
  const pageJump = () => {
    if (pageInput.value > pageLength.value || pageInput.value < 1) {
      toastInfo('页数输入错误！')

      return
    }
    if (+pageInput.value === page.value) {
      toastInfo('已是当前页数！')

      return
    }
    page.value = +pageInput.value
  }

  // 获取列表数据
  const fetchList = () => {
    loading.value = true
    const filterData = fetchFilterData()
    console.log(filterData)
    requestGet('/authentic/channelAuthenticList', filterData).then(response => {
      const { data, total } = response
      console.log(data)

      /* 给数据添加序号，当data.data.records存在时取data.data.records否则直接取data.data */
      listTable.value = getDataWithKey(data, 'records').map((v, i) => {
        // eslint-disable-next-line no-param-reassign
        v.index = i + 1

        return v
      })
      listTableCount.value = total || listTable.value.length
      pageLength.value = Math.ceil(listTableCount.value / pageSize.value) || 1
      loading.value = false
    })
  }

  // 获取列表数据
  fetchList()

  // 搜索事件
  const searchEvent = () => {
    // router.push({ name: pageData.routerPushName, query: fetchFilterData() })
    page.value = 1
    fetchList()
  }

  // 按钮组渲染，因为需要用到很多函数，所以定义在最下面
  const btnGroupList = reactive([
    {
      text: '查询',
      icon: 'mdi-magnify',
      eventHandlers: { click: () => searchEvent() },
      class: 'mx-2',
      componentProps: {
        color: 'primary',
      },
    },
  ])

  // 列表中的按钮组绑定列表当前行的item
  const bindActionsItem = item => {
    pageData.actionsItem = item
  }

  const iframeUrl = ref('')
  const showIframe = ref(false)
  const channelAuth = () => {
    /* eslint-disable */
    const platformTypeMap = {
      '抖音': '1',
      bilibili: '2',
      'B站': '2',
      '快手': '3',
    }
    if (!(getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth)) {
      modalEnquireTitle('注意📢', '请您在频道确权前，先进行实名认证。').then(res => {
        if (res.isConfirmed) {
          router.push({ name: 'page-account-settings' }).then()
        }
      })

      return
    }
    if (!platformTypeMap[pageData.actionsItem.platform]) {
      toastInfo(`目前只支持 「${Object.keys(platformTypeMap)}」 平台！`)

      return
    }
    /* eslint-enable */
    requestGet('/authentic/getAuthUrl', {
      id: pageData.actionsItem.id,
      type: platformTypeMap[pageData.actionsItem.platform],
    }).then(res => {
      iframeUrl.value = res.data.authUrl
      showIframe.value = true
      // window.open(res.data.authUrl, '_blank', 'width=1000,height=600,top=200,left=200,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes')
    })
  }

  const tableBtnList = reactive([
    {
      text: '授权',
      icon: 'mdi-badge-account-horizontal',
      eventHandlers: {
        click: channelAuth,
      },
    },
  ])

  const clickCheckAll = item => {
    if (item && item.everyItem) {
      pageData.selectedListItem = []
    } else pageData.selectedListItem = [...listTable.value]
  }

  const clickSort = item => {
    if (!isObject(item) || item.sortable === false) return
    if (item.value !== proxyQueryData.sortBy) {
      proxyQueryData.sortBy = item.value
      proxyQueryData.sortDesc = true

      return
    }
    if (proxyQueryData.sortDesc) proxyQueryData.sortDesc = false
    else {
      proxyQueryData.sortDesc = null
      proxyQueryData.sortBy = null
    }
  }

  // 监听列表页面以及查询参数
  const sort = computed(() => proxyQueryData.sortDesc && proxyQueryData.sortBy)
  watch(
    [page, sort], () => {
      fetchList()
    },
  )
  watch(
    [pageSize],
    () => {
      // router.push({ name: pageData.routerPushName, query: fetchFilterData() })
      page.value = 1
      fetchList()
    },
  )

  // 监听筛选框的输入
  queryComponents.forEach(queryItem => {
    watch(() => proxyQueryData[queryItem.VModelKey], () => {
      // router.push({ name: pageData.routerPushName, query: fetchFilterData() })
      if (queryItem.search) return
      page.value = 1
      fetchList()
    })
  })

  // 添加事件监听器，当认证后（localStorage中的标志位变化）则刷新数据
  window.addEventListener('storage', event => {
    if (event.key === 'channelAuthed') {
      if (event.newValue === 'authed') {
        fetchList()
        localStorage.removeItem('channelAuthed')
      }
    }
  })

  return {
    pageData,
    tableColumns,
    tableResolver,
    proxyQueryData,
    queryComponents,
    listTableCount,
    listTable,
    loading,
    page,
    pageSize,
    pageInput,
    pageLength,
    pageVisible,
    pageOptions,
    tableBtnList,
    btnGroupList,
    fetchFilterData,
    bindActionsItem,
    clickCheckAll,
    searchEvent,
    routeChange,
    clickSort,
    fetchList,
    pageJump,
    iframeUrl,
    showIframe
  }
}
