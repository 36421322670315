<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="8"
        sm="6"
      >
        <div
          class="fileInput"
        >
          <v-file-input
            v-model="file"
            :rules="isRequired ? [filesUploaded.length ? true : '这是必选项'] : [!file || !file.length ? true : '已选择文件，请先上传或清空文件']"
            :label="label"
            :placeholder="label"
            :disabled="isDisabled"
            :accept="acceptType"
            outlined
            dense
            multiple
            show-size
            hide-details="auto"
            clearable
            class="me-2"
          >
          </v-file-input>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <div>
          <div class="d-flex flex-wrap">
            <v-btn
              v-if="!isShowProgressBar"
              color="success"
              class="me-2"
              @click="uploadFiles"
            >
              上传
            </v-btn>
            <v-btn
              v-if="isShowProgressBar && !isPaused"
              outlined
              class="me-2"
              @click="uploadPause"
            >
              暂停
            </v-btn>
            <v-btn
              v-if="isShowProgressBar && isPaused"
              outlined
              class="me-2"
              @click="uploadResume"
            >
              继续
            </v-btn>
            <v-btn
              v-if="isShowProgressBar"
              class="me-4"
              color="error"
              @click="uploadCancel"
            >
              取消上传
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="isShowProgressBar"
      :value="progress.value"
      height="20"
      striped
      class="mt-2"
    >
      {{ Math.ceil(progress.value) }}%
    </v-progress-linear>

    <div
      v-if="filesUploaded.length > 0"
      class="fileUploadDetails my-2"
    >
      <v-card>
        <v-card-title>
          <v-btn
            v-if="filesUploaded.length > 0"
            color="error"
            small
            class="my-1 mx-1"
            @click="uploadFilesDelete"
          >
            删除所有上传文件
          </v-btn>
          <v-spacer></v-spacer>
          <div
            class="my-1 mx-1"
          >
            已上传文件数：{{ filesUploaded.length }}，总大小：{{ filesUploaded.reduce((acc, cur) => ({size: parseFloat(acc.size) + parseFloat(cur.size)}), {size: '0'}).size.toFixed(3) }} MB
          </div>
        </v-card-title>

        <v-card-text
          v-if="showUploadFile"
        >
          <v-tooltip
            v-for="(fileItem, index) in filesUploaded"
            :key="index"
            top
          >
            <template #activator="{ on, attrs }">
              <v-chip
                text-color="primary"
                color="primary"
                class="my-1 mx-2"
                small
                v-bind="attrs"
                v-on="on"
                @click="uploadFileDelete(index)"
              >
                {{ fileItem.name || fileItem.key.split('/').pop() }} | {{ fileItem.size }} MB{{ fileItem.duration ? ` | ${Math.floor(fileItem.duration / 60)}分${Math.floor(fileItem.duration % 60)}秒` : '' }}
              </v-chip>
            </template>
            <span>点击删除该上传文件</span>
          </v-tooltip>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  modalEnquireTitle,
  modalFail,
  modalSuccess, toastFail, toastInfo, toastSuccess,
} from '@core/utils/prompt'
import { required } from '@core/utils/validation'
import {
  AliOSS,
} from '@core/utils/useAliOSS'
import { getVideoData } from '@core/utils/dataProcess'

export default {
  name: 'FileInputBase',
  model: {
    prop: 'fileInputModel',
    event: 'update:file-input-model',
  },
  props: {
    fileInputModel: {
      type: Array,
      default: Array,
    },
    acceptType: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showUploadFile: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '请上传文件',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const aliOss = new AliOSS()
    const file = ref(null)
    const isShowProgressBar = ref(false)
    const isPaused = ref(false)
    const isUploaded = ref(false)
    const filesUploaded = computed({
      get: () => props.fileInputModel,
      set: val => emit('update:file-input-model', val),
    })
    const { userId } = JSON.parse(localStorage.getItem('userData')).user
    const progress = computed(() => aliOss.progressBar)

    aliOss.fetchOSS()

    /* 获取文件前缀 */
    function getPrefixPath() {
      const date = new Date()
      const getFormatDate = () => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

      return `${getFormatDate()}/${userId}/${date.valueOf()}`
    }

    // 文件上传（覆盖同名文件）
    function filesUpload() {
      // console.log(filesUploaded.value)
      if (file.value) {
        const data = file.value[0]

        // 获取视频的时长、宽高等信息
        getVideoData(data)
          .then(res => {
            const fileNameUpload = `${getPrefixPath()}_${data.name}`
            aliOss.fileUploadMulti(fileNameUpload, data).then(response => {
            // console.log(response)

              /* 判断文件是否上传成功(阿里云上是否有同名文件) */
              aliOss.list(fileNameUpload).then(result => {
              // console.log(result)
                if (result.objects.length) {
                /* 阿里云中存在同名文件（上传成功） */
                  file.value.splice(0, 1)

                  /* 若文件同名，则删除第一个文件（覆盖） */
                  const fileExistIndex = filesUploaded.value.findIndex(item => item.name === data.name)
                  if (fileExistIndex !== -1) {
                    filesUploaded.value.splice(fileExistIndex, 1)
                  }
                  filesUploaded.value.push({
                    name: data.name,
                    key: fileNameUpload,
                    size: (data.size / 1048576).toFixed(3),
                    bucket: aliOss.alioss.bucket,
                    duration: res.duration || null,
                    videoHeight: res.videoHeight || null,
                    videoWidth: res.videoWidth || null,
                  })

                  // console.log(filesUploaded.value)
                  // toastSuccess(`${data.name} 文件上传已完成,还剩下 ${file.value.length} 个文件`)
                  if (file.value.length === 0) {
                    modalSuccess(`上传已完成，累计上传：${filesUploaded.value.length} 个文件!`)
                    isUploaded.value = true
                    isShowProgressBar.value = false

                    return
                  }
                  filesUpload()
                } else {
                /* 在阿里云OSS中找不到对应文件，上传失败 */
                  modalFail('文件上传失败，请重新上传！')
                  isShowProgressBar.value = false
                  isPaused.value = false
                }
              }).catch(e => {
              /* 查询阿里云OSS中对应文件失败，也认为上传失败 */
                console.log(e)
                modalFail('文件上传失败，请重新上传！')
                isShowProgressBar.value = false
                isPaused.value = false
              })
            }).catch(error => {
              console.log(error)

              // 取消上传
              if (error.name === 'cancel') return
              modalFail('上传失败，请重试!')
              isShowProgressBar.value = false
              isPaused.value = false
            })
          })
          .catch(err => {
            modalFail('获取文件信息失败，请重试！')
            console.log(err)
            isShowProgressBar.value = false
            isPaused.value = false
          })
      }
    }

    const uploadFiles = () => {
      if (!file.value || file.value.length === 0) {
        toastInfo('还未选择文件')

        return
      }
      if (props.acceptType && props.acceptType !== '*') {
        console.log(file.value)
        const acceptSuffixList = props.acceptType.split(',').map(item => item.toLowerCase())
        console.log(acceptSuffixList)
        for (let i = 0; i < file.value.length; i += 1) {
          if (!acceptSuffixList.includes(`.${file.value[i].name.split('.').pop().toLowerCase()}`)) {
            modalFail(`仅支持上传 ${props.acceptType} 后缀的文件！`)

            return
          }
        }
      }
      modalEnquireTitle('! 注意 ATTENTION !', '请不要🙅🏻‍在文件即将要上传完成时点击暂停，可能会导致上传出错。').then(result => {
        if (result.isConfirmed) {
          const filenameArr = file.value.map(i => i.name)

          // 重名文件判断
          const fileExistList = filesUploaded.value.filter(item => filenameArr.includes(item.name)) || []
          if (fileExistList.length) {
            modalEnquireTitle('注意', `存在同名文件 ${fileExistList.map(i => i.name).join()} ，是否覆盖相关文件？`).then(answer => {
              if (answer.isConfirmed) {
                toastSuccess('开始上传')
                isShowProgressBar.value = true
                filesUpload()
              }
            })

            return
          }
          toastSuccess('开始上传')
          isShowProgressBar.value = true
          filesUpload()
        }
      })
    }

    const uploadPause = () => {
      try {
        // 暂停上传。
        aliOss.fileUploadPause()
        toastInfo('暂停上传')
        isPaused.value = true
        console.log(filesUploaded.value)
      } catch (e) {
        toastInfo('暂停上传失败，请重试')
        console.log(e)
      }
    }

    const uploadResume = () => {
      toastInfo('继续上传')
      isPaused.value = false
      console.log(filesUploaded.value)
      filesUpload()
    }

    const uploadCancel = () => {
      // 取消上传。
      aliOss.fileUploadCancel().then(res => {
        toastFail('取消上传')
        console.log(res)
        isShowProgressBar.value = false
        isPaused.value = false
      }).catch(error => {
        toastFail('取消上传失败，请重试')
        console.log(error)
      })
    }

    const uploadFilesDelete = () => {
      modalEnquireTitle('确定删除所有上传文件？', '点击确定后所有上传文件都会被删除').then(result => {
        if (result.isConfirmed) {
          // filesDelete(filesUploaded.value.reduce((acc, cur) => {
          //   acc.push(cur.key)
          //   console.log(acc)
          //
          //   return acc
          // }, [])).then(response => {
          //   console.log(response)
          filesUploaded.value = []
          file.value = []
          toastSuccess('成功删除所有文件')
          isUploaded.value = false
          isShowProgressBar.value = false

          // }).catch(error => {
          //   console.log(error.response)
          //   modalFail('删除文件失败')
          // })
        }
      })
    }

    const uploadFileDelete = fileIndex => {
      modalEnquireTitle('确定删除该上传文件？', '点击确定后该文件就会被删除').then(result => {
        if (result.isConfirmed) {
          // fileDelete(filesUploaded.value[fileIndex].key).then(response => {
          //   console.log(response)
          filesUploaded.value.splice(fileIndex, 1)
          console.log(filesUploaded.value)
          toastSuccess('成功删除文件')
          if (filesUploaded.value.length === 0) {
            isUploaded.value = false
            isShowProgressBar.value = false
          }

          // }).catch(error => {
          //   console.log(error)
          //   modalFail('删除失败')
          // })
        }
      })
    }

    function consoleTest(...mes) {
      console.log(...mes)
    }

    return {
      file,
      filesUploaded,
      progress,
      isPaused,
      isUploaded,
      isShowProgressBar,
      uploadFilesDelete,
      uploadFileDelete,
      uploadFiles,
      uploadPause,
      uploadResume,
      uploadCancel,
      consoleTest,

      // validator
      validator: { required },
    }
  },
}
</script>

<style scoped>
.fileUploadDetails {
  max-height: 200px; /* 设置容器高度 */
  overflow: auto; /* 添加滚动效果 */
}
</style>
